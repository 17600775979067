/**
 * Example Table page
 */
/* Import section */
import React from "react"
import Herotype4 from "../components/herotype4"
import Layout from "../components/layout"
import Seo from "../components/seo"

/* Page function declaration */
const Exampletablepage = () => {
  return (
    <Layout>
      <Seo
        title="Example Table page"
        description="Example Table page - do not index"
      />

      {/* Hero section */}
      <section className="section-hero-static top-section">
        <Herotype4 h1title="Examples of Tables"/>
      </section>

      {/*Static page details section */}
      <section className="section-details-static">
        <div className="wrap-maincontent-static post-content">
          <h2>Example Table 1</h2>
          <div id="table-1" className="table-css">
            <header className="thead">
              <p className="tr">
                <span className="th">Level</span>
                <span className="th">Barriers</span>
                <span className="th">Facilitators</span>
              </p>
            </header>
            <div className="tbody">
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Innovation</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content">
                    <ul>
                      <li>Privacy and security</li>
                      <li>Lack of accessibility</li>
                    </ul>
                  </span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">
                    <ul>
                      <li>Perceived usefulness</li>
                      <li>Perceived ease of use</li>
                    </ul>
                  </span>
                </span>
              </p>
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Individual professional</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content"> - </span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">
                    <ul>
                      <li>Positive attitude</li>
                      <li>Motivation to change</li>
                    </ul>
                  </span>
                </span>
              </p>
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Patient</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content">Lack of sufficient eHealth literacy</span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">-</span>
                </span>
              </p>
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Social context</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content">Negative attitude/opinion of medical professionals</span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">Positive attitude/opinion of medical professionals</span>
                </span>
              </p>
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Organizational context</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content">
                    <ul>
                      <li>Lack of resources</li>
                      <li>Lack of time and increased workload</li>
                      <li>Lack of suitable specialist staff</li>
                      <li>Structure of organization</li>
                    </ul>
                  </span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">
                    <ul>
                      <li>Management support</li>
                      <li>Communication to promote portal</li>
                    </ul>
                  </span>
                </span>
              </p>
              <p className="tr">
                <span className="td">
                  <span className="title">Level</span>
                  <span className="content">Economic and political context</span>
                </span>
                <span className="td first-content">
                  <span className="title">Barriers</span>
                  <span className="content">Financial difficulties</span>
                </span>
                <span className="td">
                  <span className="title">Facilitators</span>
                  <span className="content">Facilitating laws and regulations</span>
                </span>
              </p>
            </div>
          </div>
          <h2>Example Table 2</h2>
          <div id="table-2" className="table-css">
            <thead className="thead">
              <tr className="tr blue-green">
                <th className="th empty"></th>
                <th className="th">Very Long Table Title Column on one lines</th>
                <th className="th">Very Very Long Table Title Row on two lines</th>
              </tr>
            </thead>
            <tbody className="tbody">
              <tr className="tr blue-green">
                <td className="td">
                  <span className="title"></span>
                  <span className="content">Very Very Long Table Title Row on two liness</span>
                </td>
                <td className="td first-content">
                  <span className="title">Very Long Table Title Column on one lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
                <td className="td">
                  <span className="title">Very Very Long Table Title Row on two lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
              </tr>
              <tr className="tr blue-green">
                <td className="td">
                  <span className="title"></span>
                  <span className="content">Very Very Long Table Title Row on two liness</span>
                </td>
                <td className="td first-content">
                  <span className="title">Very Long Table Title Column on one lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
                <td className="td">
                  <span className="title">Very Very Long Table Title Row on two lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
              </tr>
              <tr className="tr blue-green">
                <td className="td">
                  <span className="title"></span>
                  <span className="content">Very Very Long Table Title Row on two liness</span>
                </td>
                <td className="td first-content">
                  <span className="title">Very Long Table Title Column on one lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
                <td className="td">
                  <span className="title">Very Very Long Table Title Row on two lines</span>
                  <span className="content">his relief funding for health systems and providers, under the CARES ACT, was to make up.s</span>
                </td>
              </tr>
            </tbody>
          </div>
          <h2>Example 3</h2>
          <table class="table-css">
            <thead class="thead">
              <tr class="tr">
                <th class="th">Steps</th>
                <th class="th">Outline</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Pre-Clinical Phase</span>
                </td>
                <td class="td">
                  <span class="title">Outline</span>
                  <span class="content">
                    <ul>
                      <li>Data collection</li>
                      <li>Repetitive non-human testing</li>
					            <li>Evaluation of toxic and pharmacological effects</li>
                    </ul>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Clinical Phase I</span>
                </td>
                <td class="td">
                  <span class="title">Outline</span>
                  <span class="content">
                    <ul>
                      <li>Small study of healthy humans</li>
                      <li>Evaluation of safety and immune responses based on various doses</li>
                      <li>Usual timeline takes 1 to 2 years, coronavirus trials expected to take 3 months</li>
                    </ul>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Clinical Phase II</span>
                </td>
                <td class="td">
                  <span class="title">Outline</span>
                  <span class="content">
                    <ul>
                      <li>Larger study group of 100 humans</li>
                      <li>Safety and efficacy assessments</li>
                      <li>Understanding of optimal dose and vaccine schedule</li>
                      <li>Usual timeline takes 2 to 3 years, coronavirus trials expected to take 8 months</li>
                    </ul>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Clinical Phase III</span>
                </td>
                <td class="td">
                  <span class="title">Outline</span>
                  <span class="content">
                    <ul>
                      <li>Larger study group of 1000 humans</li>
                      <li>Assessments of safety and efficacy</li>
                      <li>Usual timeline takes 2 to 4 years, coronavirus trials could be combined with Phase II</li>
                    </ul>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Regulatory Review</span>
                </td>
                <td class="td">
                  <span class="title">Outline</span>
                  <span class="content">
                  <ul>
                    <li>Trial data and licensing application are reviewed by government agency before approval</li>
                    <li>Can work in parallel with manufacturing</li>
                    <li>Usual timeline takes 1 to 2 years, coronavirus review expected to take a few months</li>
                  </ul>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">Steps</span>
                  <span class="content">Clinical Phase IV</span>
                </td>
              <td class="td">
                <span class="title">Outline</span>
                <span class="content">
                  <ul>
                    <li>Monitoring effectiveness in real-world conditions</li>
                    <li>Testing starts after the vaccine has been released to the public</li>
                  </ul>
                </span>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>NEWS</h2>
          <table class="table-css">
            <thead class="thead">
              <tr class="tr">
                <th class="th">WHEN</th>
                <th class="th">WHERE</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Wednesday, 17<sup>th</sup> October</span>
                </td>
                <td class="td">
                  <span class="title">WHERE</span>
                  <span class="content">
                    <strong>QUEENSLAND</strong><br></br>
                    <a href="https://www.google.com.au/maps/place/Level+7%2F410+Queen+St,+Brisbane+City+QLD+4000,+Australie/@-27.465639,153.0277398,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915a1d9bfbe713:0x6d58088c62be4971!8m2!3d-27.465639!4d153.0299338" target="_blank" rel="noopener noreferrer">Level 7, 410 Queen St, Brisbane QLD 4000</a>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Wednesday, 31<sup>th</sup> October</span>
                </td>
                <td class="td">
                  <span class="title">WHERE</span>
                  <span class="content">
                    <strong>WESTERN AUSTRALIA</strong><br></br>
                    <a href="https://www.google.com.au/maps/place/34+Harrogate+St,+West+Leederville+WA+6007,+Australie/@-31.9411168,115.8385092,17z/data=!3m1!4b1!4m5!3m4!1s0x2a32a5377f538367:0x9371f5d439867034!8m2!3d-31.9411168!4d115.8407032" target="_blank" rel="noopener noreferrer">34 Harrogate Street, West Leederville WA 6000</a>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Thursday, 15<sup>th</sup> November</span>
                </td>
                <td class="td">
                  <span class="title">WHERE</span>
                  <span class="content">
                    <strong>VICTORIA</strong><br></br>
                    <a href="https://www.google.com.au/maps/place/100+Wellington+Parade,+East+Melbourne+VIC+3002,+Australie/@-37.8163056,144.9845482,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad642ebd999ee81:0x11a0a6eea58f52b0!8m2!3d-37.8163056!4d144.9867422" target="_blank" rel="noopener noreferrer">100 Wellington Parade, East Melbourne VIC 3002</a>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Tuesday, 20<sup>th</sup> November</span>
                </td>
                <td class="td">
                  <span class="title">WHERE</span>
                  <span class="content">
                    <strong>NEW SOUTH WALES &amp; ACT</strong><br></br>
                    <a href="https://www.google.com.au/maps/place/Level+7%2F12+Mount+St,+North+Sydney+NSW+2060,+Australie/@-33.8383177,151.2028139,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12aef222867c35:0xcb9967dd8496c827!8m2!3d-33.8383177!4d151.2050079" target="_blank" rel="noopener noreferrer">Level 7, 12 Mount Street, North Sydney, NSW, 2060</a>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Tuesday, 27<sup>th</sup> November</span>
                </td>
                <td class="td">
                  <span class="title">WHERE</span>
                  <span class="content">
                    <strong>TASMANIA</strong><br></br>
                    <a href="https://www.google.com.au/maps/place/RACGP+-+The+Royal+Australian+College+of+General+Practitioners+-+Tasmania+Faculty/@-42.8777026,147.3304359,17z/data=!3m1!4b1!4m5!3m4!1s0xaa6e74e2d918c355:0xbfbbcada974ae5f3!8m2!3d-42.8777026!4d147.3326299" target="_blank" rel="noopener noreferrer">Level 1, ABC Centre, 1-7 Liverpool Street, Hobart TAS 7000</a>
                  </span>
                </td>
              </tr>
              <tr class="tr">
                <td class="td">
                  <span class="title">WHEN</span>
                  <span class="content">Wednesday, 5<sup>th</sup> December</span>
                </td>
              <td class="td">
                <span class="title">WHERE</span>
                <span class="content">
                  <strong>SOUTH AUSTRALIA &amp; NT</strong><br></br>
                  <a href="https://www.google.com.au/maps/place/RACGP+-+The+Royal+Australian+College+of+General+Practitioners+-+SA%26NT+Faculty/@-34.9038689,138.596919,17z/data=!3m1!4b1!4m5!3m4!1s0x6ab0c9275769f159:0x65623fc4f52a6a6f!8m2!3d-34.9038689!4d138.599113" target="_blank" rel="noopener noreferrer">College House, 15 Gover Street, North Adelaide SA 5006</a>
                </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  )
}


/* Export page informations */
export default Exampletablepage
